@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.engagement-gallery-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .engagement-gallery-header {
    font-size: 4em;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .engagement-gallery-header {
    font-size: 4em;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }