@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.story-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}

.story-date {
    text-align: center;
}

.story-picture-div {
    text-align: center;
}

.story-fill {
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.howwemet-picture {
    max-width: 250px;
    max-height: 250px;
}

.story-container {
    background-color: #E7EEEA;
    padding-bottom: 60px;
}

.story-text {
    margin-bottom: 0;
}

@media (max-width: 1199.98px) { 
    .howwemet-picture {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .story-header {
    font-size: 4em;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .story-header {
    font-size: 4em;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .story-header {
    font-size: 6em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .story-header {
    font-size: 6em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .story-header {
    font-size: 6em;
    }
 }