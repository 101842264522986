@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Parisienne&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: calc(100% - 60px) !important;
  height: calc(100% - 60px) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  min-height: 100%;
  height: 100%;
}

#root {
  min-height: 100%;
  height: 100%;
}
.App {
  min-height: 100%;
  height: 100%;
  background-color: #E7EEEA;
}

.page {
  min-height: 100%;
  height: 100%;
}

/* Dusty Blue: #9aaaba */
/* Blush Pink: #dfccc5 */
/* Light Green: #c2c9bf */
.homepage-jumbotron {
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    height:100%;
    background-color: #c9f4ff !important;
}

.homepage-title {
    font-family: 'Parisienne', cursive;
    color: black;
}

.homepage-date {
    font-family: 'Parisienne', cursive;
    color: black;
    font-weight: bold;
}

.home-fill {
    text-align: center;
    min-height: 100%;
    height: 100%;
    background-color: #c9f4ff;
}

.jumbotron-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Parisienne', cursive;
    color: black;
    font-weight: bold;
    padding-top: 30px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile.png");
    }

    .homepage-title {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1999.98px) { 
    .homepage-jumbotron {
        max-height: 1020px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-1080.png");
    }

    .homepage-title {
        font-size: 6rem;
        font-weight: 500;
        line-height: 2;
    }

    .homepage-date {
        font-size: 4rem;
        line-height: 1.5;
    }
 }

 /* Ultra wide and 4k devices (large desktops, 2000px and up) */
@media (min-width: 2000px) { 
    .homepage-jumbotron {
        min-height: 1080px;
        background-position: center center;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-4k.png");
    }

    .homepage-title {
        font-size: 8rem;
        font-weight: 500;
        line-height: 2;
    }

    .homepage-date {
        font-size: 6rem;
        line-height: 1.5;
    }
 }
.story-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}

.story-date {
    text-align: center;
}

.story-picture-div {
    text-align: center;
}

.story-fill {
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.howwemet-picture {
    max-width: 250px;
    max-height: 250px;
}

.story-container {
    background-color: #E7EEEA;
    padding-bottom: 60px;
}

.story-text {
    margin-bottom: 0;
}

@media (max-width: 1199.98px) { 
    .howwemet-picture {
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }
 }

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .story-header {
    font-size: 4em;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .story-header {
    font-size: 4em;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .story-header {
    font-size: 6em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .story-header {
    font-size: 6em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .story-header {
    font-size: 6em;
    }
 }
.gallery-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .gallery-header {
    font-size: 4em;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .gallery-header {
    font-size: 4em;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .gallery-header {
    font-size: 8em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .gallery-header {
    font-size: 8em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .gallery-header {
    font-size: 8em;
    }
 }
.engagement-gallery-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .engagement-gallery-header {
    font-size: 4em;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .engagement-gallery-header {
    font-size: 4em;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .engagement-gallery-header {
    font-size: 8em;
    }
 }
.event-details-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
    padding-bottom: 30px;
}

.event-details-text {
    text-align: center;
}

.event-details-date {
    text-align: center;
    font-weight: bold;
}

.event-details-location {
    text-align: center;
}

.event-details-fill {
    text-align: center;
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.event-details-link {
    text-decoration: underline;
    color: #212529;
}

.event-details-link:hover {
    text-decoration: underline;
    color: #212529;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .event-details-header {
    font-size: 4em;
    }
    
    .event-details-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .event-details-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .event-details-header {
    font-size: 4em;
    }

    .event-details-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .event-details-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .event-details-header {
    font-size: 8em;
    }

    .event-details-text {
    font-size: 3em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .event-details-header {
    font-size: 8em;
    }
    
    .event-details-text {
    font-size: 3em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .event-details-header {
    font-size: 8em;
    }

    .event-details-text {
    font-size: 3em;
    }
 }
.travel-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
    padding-bottom: 30px;
}

.travel-image {
    min-width: 300px;
    min-height: 300px;
}

.travel-fill {
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.hotel-link {
    text-decoration: underline;
    color: #212529;
}

.hotel-link:hover {
    text-decoration: underline;
    color: #212529;
}

.rental-link {
    text-decoration: underline;
    color: #212529;
}

.rental-link:hover {
    text-decoration: underline;
    color: #212529;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .travel-header {
    font-size: 4em;
    }
    
    .travel-text {
    font-size: 2em;
    padding-bottom: 0px;
    }

    .travel-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .travel-header {
    font-size: 4em;
    }

    .travel-text {
    font-size: 2em;
    padding-bottom: 0px;
    }

    .travel-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .travel-header {
    font-size: 8em;
    }

    .travel-text {
    font-size: 2em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .travel-header {
    font-size: 8em;
    }
    
    .travel-text {
    font-size: 2em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .travel-header {
    font-size: 8em;
    }

    .travel-text {
    font-size: 2em;
    }
 }
.seattle-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
    padding-bottom: 30px;
}

.seattle-image {
    min-width: 300px;
    min-height: 300px;
}

.seattle-fill {
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.seattle-link {
    text-decoration: underline;
    color: #212529;
}

.seattle-link:hover {
    text-decoration: underline;
    color: #212529;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .seattle-header {
    font-size: 4em;
    }
    
    .seattle-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .seattle-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .seattle-header {
    font-size: 4em;
    }

    .seattle-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .seattle-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .seattle-header {
    font-size: 8em;
    }

    .seattle-text {
    font-size: 2em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .seattle-header {
    font-size: 8em;
    }
    
    .seattle-text {
    font-size: 2em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .seattle-header {
    font-size: 8em;
    }

    .seattle-text {
    font-size: 2em;
    }
 }
.rsvp-fill {
    text-align: center;
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}


#details-dropdown{
  color:white !important;
}

.dropdown-menu {
  background-color: #5A716C !important;
  border:0px !important;
}

.custom-navlink {
  color:white !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.dropdown-item {
  color:white !important;
  font-weight: bold !important;
  font-size: 1em !important;
  text-align: center !important;
}

.dropdown-item:hover {
  background-color: #5A716C !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.nav-link {
  font-weight: bold !important;
  font-size: 1.2em !important;
}

.custom-navbar {
  background-color: #5A716C;
  text-align: center;
}

.navbar-light .navbar-toggler {
    color:white !important;
    border-color: white !important;
}

.story-text {
  font-size: 1.5rem;
}
  .footer {
    background: #5A716C;
    height: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
  
  .footer-text {
    color: white;
    font-size: 1rem;
  }
