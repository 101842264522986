  .footer {
    background: #5A716C;
    height: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
  }
  
  .footer-text {
    color: white;
    font-size: 1rem;
  }