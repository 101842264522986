
#details-dropdown{
  color:white !important;
}

.dropdown-menu {
  background-color: #5A716C !important;
  border:0px !important;
}

.custom-navlink {
  color:white !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.dropdown-item {
  color:white !important;
  font-weight: bold !important;
  font-size: 1em !important;
  text-align: center !important;
}

.dropdown-item:hover {
  background-color: #5A716C !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.nav-link {
  font-weight: bold !important;
  font-size: 1.2em !important;
}

.custom-navbar {
  background-color: #5A716C;
  text-align: center;
}

.navbar-light .navbar-toggler {
    color:white !important;
    border-color: white !important;
}

.story-text {
  font-size: 1.5rem;
}