.App {
  min-height: 100%;
  height: 100%;
  background-color: #E7EEEA;
}

.page {
  min-height: 100%;
  height: 100%;
}

/* Dusty Blue: #9aaaba */
/* Blush Pink: #dfccc5 */
/* Light Green: #c2c9bf */