@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.homepage-jumbotron {
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    height:100%;
    background-color: #c9f4ff !important;
}

.homepage-title {
    font-family: 'Parisienne', cursive;
    color: black;
}

.homepage-date {
    font-family: 'Parisienne', cursive;
    color: black;
    font-weight: bold;
}

.home-fill {
    text-align: center;
    min-height: 100%;
    height: 100%;
    background-color: #c9f4ff;
}

.jumbotron-text {
    display: flex;
    align-items: center;
    font-family: 'Parisienne', cursive;
    color: black;
    font-weight: bold;
    padding-top: 30px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile.png");
    }

    .homepage-title {
        font-size: 2.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .homepage-jumbotron {
        min-height: 960px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-mobile-hd.png");
    }

    .homepage-title {
        font-size: 3.5rem;
        font-weight: bold;
        line-height: 1;
    }

    .homepage-date {
        font-size: 2rem;
        line-height: 1;
    }

 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1999.98px) { 
    .homepage-jumbotron {
        max-height: 1020px;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-1080.png");
    }

    .homepage-title {
        font-size: 6rem;
        font-weight: 500;
        line-height: 2;
    }

    .homepage-date {
        font-size: 4rem;
        line-height: 1.5;
    }
 }

 /* Ultra wide and 4k devices (large desktops, 2000px and up) */
@media (min-width: 2000px) { 
    .homepage-jumbotron {
        min-height: 1080px;
        background-position: center center;
        background-image: url("https://shelbycooperwedding.blob.core.windows.net/images/home/wedding-balloon-4k.png");
    }

    .homepage-title {
        font-size: 8rem;
        font-weight: 500;
        line-height: 2;
    }

    .homepage-date {
        font-size: 6rem;
        line-height: 1.5;
    }
 }