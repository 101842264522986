@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.seattle-header {
    text-align: center;
    font-family: 'Parisienne', cursive;
    font-weight: bold;
    padding-bottom: 30px;
}

.seattle-image {
    min-width: 300px;
    min-height: 300px;
}

.seattle-fill {
    min-height: 100%;
    background-color: #E7EEEA;
    padding-bottom: 30px;
}

.seattle-link {
    text-decoration: underline;
    color: #212529;
}

.seattle-link:hover {
    text-decoration: underline;
    color: #212529;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .seattle-header {
    font-size: 4em;
    }
    
    .seattle-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .seattle-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) { 
    .seattle-header {
    font-size: 4em;
    }

    .seattle-text {
    font-size: 1.5em;
    padding-bottom: 0px;
    }

    .seattle-image {
    min-width: 150px;
    min-height: 150px;
    }
 }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) { 
    .seattle-header {
    font-size: 8em;
    }

    .seattle-text {
    font-size: 2em;
    }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .seattle-header {
    font-size: 8em;
    }
    
    .seattle-text {
    font-size: 2em;
    }
 }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .seattle-header {
    font-size: 8em;
    }

    .seattle-text {
    font-size: 2em;
    }
 }